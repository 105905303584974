import { Trans } from '@lingui/macro';
import React from 'react';

import { Heading2 } from 'uikit/Heading';
import { TextDisplay } from 'uikit/Text';

import './BrandStats.scss';

const BrandStats = () => {
  return (
    <section className="brandStats">
      <Heading2>
        <Trans>Fixly w liczbach</Trans>
      </Heading2>
      <div className="brandStats__wrap">
        <div className="brandStats__item">
          <Heading2 as="h3" className="brandStats__itemTitle">
            1 200 000+
          </Heading2>
          <TextDisplay className="brandStats__itemText">
            <Trans>zarejestrowanych klientów</Trans>
          </TextDisplay>
        </div>
        <div className="brandStats__item">
          <Heading2 as="h3" className="brandStats__itemTitle">
            400+
          </Heading2>
          <TextDisplay className="brandStats__itemText">
            <Trans>kategorii usług</Trans>
          </TextDisplay>
        </div>
        <div className="brandStats__item">
          <Heading2 as="h3" className="brandStats__itemTitle">
            1 500 000+
          </Heading2>
          <TextDisplay className="brandStats__itemText">
            <Trans>dodanych zleceń</Trans>
          </TextDisplay>
        </div>
      </div>
    </section>
  );
};

export default BrandStats;
