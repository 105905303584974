import classnames from 'classnames';
import React, { FC, useEffect, useState } from 'react';

import { isWindow } from 'common/helpers/helpers';
import { ReactComponent as ArrowUpIcon } from 'common/icons/arrow-up.svg';
import { FabButton } from 'uikit/Button';

import { withNoSSR } from '../NoSSR';
import './PageScroller.scss';

type Props = {
  className?: string;
  threshold?: number;
};

const PageScroller: FC<Props> = (props) => {
  // TODO: fix the problem GS-9213
  // eslint-disable-next-line ssr-friendly/no-dom-globals-in-react-fc
  const threshold = props.threshold || window.innerHeight;
  // eslint-disable-next-line ssr-friendly/no-dom-globals-in-react-fc
  const [visible, setVisible] = useState<boolean>(window.scrollY >= threshold);

  useEffect(() => {
    const handler = () => setVisible(window.scrollY >= threshold);
    window.addEventListener('scroll', handler);

    return () => window.removeEventListener('scroll', handler);
  }, [threshold]);

  if (!isWindow || !visible) return null;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const hostCN = classnames('pageScroller', props.className);
  return <FabButton className={hostCN} icon={<ArrowUpIcon aria-hidden />} kind="cta" onClick={scrollToTop} />;
};

export default withNoSSR(PageScroller);
