import React from 'react';

import { Heading2 } from 'uikit/Heading';

import './MediaAboutUs.scss';
import moneyLogo from './images/moneypl.svg';
import naszemiastoLogo from './images/naszemiasto.svg';
import natematLogo from './images/natemat.svg';
import wirtualnemediaLogo from './images/wirtualnemedia.svg';
import wprostLogo from './images/wprost.svg';

type MediaItem = {
  logo: string;
  width: string;
  height: string;
  url: string;
  alt: string;
};

const media: MediaItem[] = [
  {
    logo: natematLogo,
    width: '135',
    height: '52',
    alt: 'natemat.pl',
    url: 'https://natemat.pl/309107,fixly-dla-klientow-aplikcja-ktora-pomoze-znalezc-sprawdzonego-specjaliste',
  },
  {
    logo: wprostLogo,
    width: '135',
    height: '52',
    alt: 'wprost.pl',
    url: 'https://www.wprost.pl/zycie/10224402/jak-znalezc-sprawdzonego-fachowca.html',
  },
  {
    logo: moneyLogo,
    width: '135',
    height: '52',
    alt: 'money.pl',
    url: 'https://www.money.pl/gospodarka/fixly-startup-ktory-znajdzie-dla-ciebie-fachowca-6416592190892161a.html',
  },
  {
    logo: wirtualnemediaLogo,
    width: '237',
    height: '52',
    alt: 'wirtualnemedia.pl',
    url: 'https://www.wirtualnemedia.pl/artykul/fixly-nowa-kategoria-i-reklama-nie-kupuj-naprawiaj',
  },
  {
    logo: naszemiastoLogo,
    width: '166',
    height: '52',
    alt: 'naszemiasto.pl',
    url: 'https://naszemiasto.pl/jak-w-5-minut-zlecic-usluge-i-znalezc-fachowca/ar/c15-8191413',
  },
];

const MediaAboutUs = () => {
  return (
    <section className="mediaAboutUs" lang="uk">
      <Heading2>Що пишуть про нас</Heading2>
      <div className="mediaAboutUs__wrap">
        {media.map((item, index) => (
          <a
            key={`mediaAboutUs${index}`}
            className="mediaAboutUs__logo"
            target="_blank"
            rel="noopener noreferrer"
            href={item.url}
          >
            <img src={item.logo} width={item.width} height={item.height} alt={item.alt} />
          </a>
        ))}
      </div>
    </section>
  );
};

export default MediaAboutUs;
