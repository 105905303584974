import React from 'react';

import { Heading2 } from 'uikit/Heading';
import { TextDisplay } from 'uikit/Text';

import './BrandStats.scss';

const BrandStats = () => {
  return (
    <section className="brandStats" lang="uk">
      <Heading2>Fixly в цифрах</Heading2>
      <div className="brandStats__wrap">
        <div className="brandStats__item">
          <Heading2 as="h3" className="brandStats__itemTitle">
            1 200 000+
          </Heading2>
          <TextDisplay className="brandStats__itemText">зареєстрованих клієнтів</TextDisplay>
        </div>
        <div className="brandStats__item">
          <Heading2 as="h3" className="brandStats__itemTitle">
            400+
          </Heading2>
          <TextDisplay className="brandStats__itemText">категорій послуг</TextDisplay>
        </div>
        <div className="brandStats__item">
          <Heading2 as="h3" className="brandStats__itemTitle">
            1 500 000+
          </Heading2>
          <TextDisplay className="brandStats__itemText">розміщених замовлень</TextDisplay>
        </div>
      </div>
    </section>
  );
};

export default BrandStats;
