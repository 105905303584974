import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { useState, FC, ReactNode } from 'react';

import { Heading2 } from 'uikit/Heading';

import './Faq.scss';

type FaqProps = {
  question: string;
  answer: ReactNode;
};

const questions = [
  {
    question: t`Jak zacząć korzystać z Fixly?`,
    answer: (
      <Trans id="register-faq-answer-1">
        Najlepiej od rejestracji konta. Wybierz czym się zajmujesz, wpisz nazwę swojej miejscowości i podaj adres
        e-mail. W kolejnym kroku uzupełnij informacje o sobie i dodaj zdjęcie profilowe. Pamiętaj o dodaniu również
        zdjęć swoich realizacji, żeby zainteresować zlecających i zwiększyć swoje szanse na otrzymanie zlecenia.
      </Trans>
    ),
  },
  {
    question: t`Jak znaleźć na Fixly nowych klientów?`,
    answer: (
      <Trans id="register-faq-answer-2">
        Nie musisz ich nawet szukać! Gdy zlecający doda w serwisie zapytanie, dotyczące świadczonych przez Ciebie usług
        - poinformujemy Cię o tym. Jeśli zapytanie Cię zainteresuje, możesz skontaktować się ze zlecającym, dopytać o
        wszelkie szczegóły i umówić na realizację usługi.
      </Trans>
    ),
  },
  {
    question: t`Jak zdobywać opinie na Fixly?`,
    answer: (
      <Trans id="register-faq-answer-3">
        Od ilości opinii na Twoim koncie zależy, czy zlecający sięgną po telefon, by zadzwonić do Ciebie i umówić się na
        realizację usługi. Im więcej opinii, tym więcej zleceń. Nawet jeśli jeszcze nie wykonałeś żadnego zlecenia z
        Fixly, możesz poprosić o rekomendacje klientów spoza naszego serwisu. Wystarczy, że wejdziesz w Ustawienia na
        swoim koncie, a następnie w zakładkę Opinie i roześlesz do nich prośby o ocenę. Dzięki temu szybciej zaczniesz
        zdobywać zlecenia.
      </Trans>
    ),
  },
];

const FaqItem: FC<FaqProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const questionCN = classnames('providerRegisterFaq', {
    'providerRegisterFaq_open': isOpen,
  });

  return (
    <div className={questionCN} itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
      <button
        className="providerRegisterFaq__question"
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        itemProp="name"
      >
        {question}
      </button>
      <div
        className="providerRegisterFaq__panel"
        itemScope
        itemProp="acceptedAnswer"
        itemType="https://schema.org/Answer"
      >
        <p className="providerRegisterFaq__answer" itemProp="text">
          {answer}
        </p>
      </div>
    </div>
  );
};

const Faq: FC = () => {
  return (
    <section className="providerRegisterFaqs" id="faq">
      <div className="container">
        <Heading2>
          <Trans>Sprawdź odpowiedzi na najpopularniejsze pytania</Trans>
        </Heading2>
        <div className="providerRegisterFaqs__accordion" itemScope itemType="https://schema.org/FAQPage">
          {questions.map((item) => (
            <FaqItem key={item.question} question={item.question} answer={item.answer} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faq;
