import { Trans } from '@lingui/macro';
import c from 'classnames';
import React, { useState } from 'react';

import config from 'config/config';

import './TermsAndConditions.scss';

type TermsAndConditions$Props = {
  tosUrl?: string;
  privacyUrl?: string;
  cookieUrl?: string;
};

const TermsAndConditions: React.FC<TermsAndConditions$Props> = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { tosUrl, privacyUrl, cookieUrl } = props;
  const tosCN = c('termsAndConditions', {
    'termsAndConditions_expanded': isExpanded,
  });
  return (
    <div className={tosCN}>
      <span className="termsAndConditions__text">
        <Trans>
          Klikając przycisk kontynuowania, akceptuję{' '}
          <a href={tosUrl || config.HELPDESK_TOS_URL} target="_blank" rel="noreferrer">
            Regulamin Fixly
          </a>
          .<br />
          Przyjmuję do wiadomości, że <b>Grupa OLX</b> sp. z o.o. wykorzystuje moje dane osobowe zgodnie z{' '}
          <a href={privacyUrl || config.HELPDESK_PRIVACY_URL} target="_blank" rel="noreferrer">
            Polityką prywatności
          </a>{' '}
          oraz{' '}
          <a href={cookieUrl || config.HELPDESK_COOKIE_URL} target="_blank" rel="noreferrer">
            Polityką dotyczącą plików cookie i podobnych technologii
          </a>
          .
        </Trans>
      </span>
      <br />
      <button type="button" className="termsAndConditions__expand" onClick={() => setIsExpanded(true)}>
        <Trans>Rozwiń…</Trans>
      </button>
      <p className="termsAndConditions__text termsAndConditions__text_expandable">
        <Trans id="user.register_email_only.tos">
          Grupa OLX sp. z o.o. wykorzystuje zautomatyzowane systemy i partnerów do analizowania, w jaki sposób korzystam
          z usług w celu zapewnienia odpowiedniej funkcjonalności produktu, treści, dostosowanych i opartych na
          zainteresowaniach reklam, jak również ochrony przed spamem, złośliwym oprogramowaniem i nieuprawnionym
          korzystaniem z naszych usług.
        </Trans>
      </p>
    </div>
  );
};

export default TermsAndConditions;
