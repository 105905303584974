import { useQuery } from '@apollo/client';
import { Trans } from '@lingui/macro';
import React, { FC } from 'react';

import { Checkbox } from 'uikit/Checkbox';
import Error from 'uikit/Error/Error';
import { TextDisplay } from 'uikit/Text';
import analytics from 'utils/analytics/analytics';

import { topCategoriesQuery, TopCategoryResponse } from '../ProviderRegisterForm.helpers';
import './ProviderRegisterCategories.scss';

type Props = {
  value: string[];
  error?: string;
  onChange?: (ids: string[]) => void;
};

const ProviderRegisterCategories: FC<Props> = (props) => {
  const { data } = useQuery<TopCategoryResponse>(topCategoriesQuery);

  const isChecked = (id: string): boolean => {
    return props.value.includes(id);
  };

  const updateValue = (id: string, checked: boolean): void => {
    const ids = new Set(props.value);
    if (checked) {
      ids.add(id);
    } else {
      ids.delete(id);
    }
    if (props.onChange) props.onChange(Array.from(ids));
  };

  const trackEvent = (id: string, checked: boolean): void => {
    analytics.trackEvent(checked ? 'sp_registration_l2_select' : 'sp_registration_l2_unselect', {
      l2_id: id,
    });
  };

  const onCategoryChange = (id: string, checked: boolean): void => {
    updateValue(id, checked);
    trackEvent(id, checked);
  };

  if (!data) return null;

  const limit = data.config.provider.specializationLimit;

  return (
    <fieldset className="providerRegisterCategories">
      <TextDisplay as="legend" bold>
        <Trans>Zaznacz, czym się zajmujesz (max. {limit} kategorie):</Trans>
      </TextDisplay>
      <div className="providerRegisterCategories__items">
        {data.topCategoryList.map((item) => (
          <Checkbox
            key={item.slug}
            id={`category-${item.id}`}
            value={item.id}
            label={item.name}
            className="providerRegisterCategories__item"
            checked={isChecked(item.id)}
            disabled={props.value.length >= limit && !isChecked(item.id)}
            onChange={(event) => onCategoryChange(item.id, event.currentTarget.checked)}
          />
        ))}
      </div>
      {props.error && <Error className="providerRegisterCategories__error">{props.error}</Error>}
    </fieldset>
  );
};

export default ProviderRegisterCategories;
