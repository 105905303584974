import React, { FC } from 'react';

import './Disclaimer.scss';

const UkrainianDisclaimer: FC = () => {
  return (
    <div className="ukrainianDisclaimer">
      <strong className="container ukrainianDisclaimer__inner">
        Май на увазі, що сервіс Fixly працює польською мовою. У разі протиріччя між змістом, перекладеним українською
        мовою, та польською мовою, вирішальним є зміст польською мовою.
      </strong>
    </div>
  );
};

export default UkrainianDisclaimer;
