import { useQuery } from '@apollo/client';
import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FC, useState } from 'react';

import OrdersAccordion from 'Components/OrdersAccordion/OrdersAccordion';
import { Button } from 'uikit/Button';
import { Heading2 } from 'uikit/Heading';

import {
  buildOrdersTree,
  ordersQuery,
  OrdersResponse,
  shortListedCategories,
  sortCategoriesByIds,
} from './ProviderRegisterOrders.helpers';
import './ProviderRegisterOrders.scss';

type Props = {
  classname?: string;
  city?: string;
};

const ProviderRegisterOrders: FC<Props> = (props) => {
  const { data, loading } = useQuery<OrdersResponse>(ordersQuery, { variables: { city: props.city } });
  const [sectionsCount, setSectionsCount] = useState<number>(shortListedCategories.length);

  if (!data) return null;

  const showAll = (): void => {
    setSectionsCount(data.topCategoryList.length);
  };
  const hasMore = sectionsCount < data.topCategoryList.length;

  const sorted = sortCategoriesByIds(data.topCategoryList, shortListedCategories);
  const ordersTree = buildOrdersTree(sorted);

  const hostCN = classnames('providerRegisterOrders', props.classname);
  return (
    <section className={hostCN} id="orders">
      <div className="providerRegisterOrders__layout">
        <Heading2>
          <Trans>Zlecenia</Trans>
        </Heading2>
        <OrdersAccordion
          className="providerRegisterOrders__items"
          orders={ordersTree}
          numberOfVisible={sectionsCount}
        />
        {hasMore && (
          <div className="providerRegisterOrders__actions">
            <Button
              kind="secondary"
              width="wide"
              onClick={showAll}
              disabled={loading}
              isLoading={loading}
              data-testid="load-all-orders"
            >
              <Trans>Zobacz więcej zleceń</Trans>
            </Button>
          </div>
        )}
      </div>
    </section>
  );
};

export default ProviderRegisterOrders;
