import { t, Trans } from '@lingui/macro';
import React from 'react';
import Slider, { Settings } from 'react-slick';

import RatingStars from 'common/RatingStars/RatingStars';
import { ReactComponent as StarIcon } from 'common/icons/star.svg';
import 'common/styles/SlickSlider.scss';
import { Heading3 } from 'uikit/Heading';
import { TextMeta, TextP3 } from 'uikit/Text';

import './AppReviews.scss';

const sliderSettings: Settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  draggable: false,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 568,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

type ReviewItem = {
  date: string;
  author: string;
  store: string;
  rating: number;
  description: string;
};

const googleMarketName = 'Google Play';
const appleMarketName = 'App Store';

const reviews: ReviewItem[] = [
  {
    date: '14/05/2021',
    author: 'Jacek Szumielewicz',
    rating: 5,
    store: t`Opinia z ${googleMarketName}`,
    description: 'Super aplikacja. Pomaga mi znaleźć zleceniodawców, zlecenia i opinie wykonanej pracy👍🙌😁',
  },
  {
    date: '12/06/2020',
    author: 'Tomasz Janikowski',
    rating: 5,
    store: t`Opinia z ${googleMarketName}`,
    description:
      // eslint-disable-next-line max-len
      'Mam stronę internetową, robię e-mailing i na różne sposoby docieram do klientów, ale to dzięki tej aplikacji mam pierwsze realne zlecenia. Polecam.',
  },
  {
    date: '29/04/2020',
    author: 'JJJacek',
    rating: 5,
    store: t`Opinia z ${appleMarketName}`,
    description:
      'Tego mi było trzeba. Wszystkie zlecenia w jednym miejscu, wygodny kontakt z klientami, spoko obsługa.',
  },
  {
    date: '11/07/2020',
    author: '',
    rating: 5,
    store: t`Opinia z ${googleMarketName}`,
    description: 'Bardzo dobra aplikacja, szybki kontakt z potencjalnymi zleceniodawcami. Dla mnie idealna!',
  },
  {
    date: '09/07/2020',
    author: 'OliSalman',
    rating: 5,
    store: t`Opinia z ${appleMarketName}`,
    description:
      // eslint-disable-next-line max-len
      'Jest najlepsza na świecie nic dodać nic ująć. Gratuluję, ale też dziękuję. Życzę jeszcze większych sukcesów i osiągnięć . Dziękuję',
  },
  {
    date: '18/07/2020',
    author: 'Rafał Pieslak',
    rating: 5,
    store: t`Opinia z ${googleMarketName}`,
    description:
      // eslint-disable-next-line max-len
      'Bardzo dobra aplikacja bardzo dużo zleceń oraz wsparcie zespołu Fixly w potrzebie naprawdę polecam wszystkim wykonawcom, którzy chcą rozwinąć swoją działalność. Ten portal w tym pomoże naprawdę bez ściemy...',
  },
  {
    date: '15/01/2021',
    author: 'braciajurzysta',
    rating: 5,
    store: t`Opinia z ${appleMarketName}`,
    description: 'Najlepszy portal dla wykonawców w Polsce! Tani i szybki bez zbędnego pitu pitu',
  },
  {
    date: '03/04/2021',
    author: 'Łukas Bartkowiak',
    rating: 4,
    store: t`Opinia z ${googleMarketName}`,
    description: 'Fajnie płynnie działa. Dodałbym jeszcze taką usługę jak pranie tapicerek samochodowych i meblowych',
  },
  {
    date: '20/07/2021',
    author: 'Marek Wojewódzki',
    rating: 5,
    store: t`Opinia z ${googleMarketName}`,
    description: 'Bardzo fajna aplikacja. Szybko i sprawnie można kontaktować się z klientami. Polecam. Maras Dach Bud',
  },
  {
    date: '19/11/2020',
    author: 'Łukasz Sałek',
    rating: 5,
    store: t`Opinia z ${googleMarketName}`,
    description:
      // eslint-disable-next-line max-len
      'Fajna sprawa, otrzymuję zapytanie o naprawę, montaż, wymianę z numerem telefonu osoby zlecającej pracę, dzwonię i umawiam się na robotę.',
  },
  {
    date: '20/03/2021',
    author: 'Szymon Szymon',
    rating: 5,
    store: t`Opinia z ${googleMarketName}`,
    description: 'Najlepsza aplikacja. Wszystko w jednym miejscu. Polecam!',
  },
  {
    date: '27/10/2020',
    author: 'Sylwester Dul',
    rating: 5,
    store: t`Opinia z ${googleMarketName}`,
    description: 'Klasa naprawdę godna polecenia',
  },
  {
    date: '29/07/2020',
    author: 'Kamil Zygmański',
    rating: 5,
    store: t`Opinia z ${googleMarketName}`,
    description: 'Bardzo intuicyjna, uczciwa i przydatna aplikacja. Polecam każdemu!!',
  },
  {
    date: '07/07/2020',
    author: 'Agnieszka Knigawka',
    rating: 5,
    store: t`Opinia z ${googleMarketName}`,
    description:
      'Uważam, że jest to aplikacja, przez którą można zdobyć wielu ciekawych klientów jednorazowych jak i stałych',
  },
  {
    date: '07/04/2021',
    author: 'Adrian Rogoda',
    rating: 5,
    store: t`Opinia z ${googleMarketName}`,
    description: 'Aplikacja czytelna i jasna w swojej prostocie',
  },
  {
    date: '15/02/2021',
    author: 'Lech Żmijewski',
    rating: 5,
    store: t`Opinia z ${googleMarketName}`,
    description: 'Bardzo fajna aplikacja otwiera możliwości na nowe ciekawe realizacje',
  },
  {
    date: '06/04/2021',
    author: 'Marcin Skorupski',
    rating: 5,
    store: t`Opinia z ${googleMarketName}`,
    description: 'Kilka zleceń zdobyłem a jestem stosunkowo krótko 🙂',
  },
];

const AppReviews = () => {
  return (
    <section className="appReviews">
      <Heading3 as="h2" className="appReviews__title">
        <Trans>Jak inni wykonawcy oceniają aplikację Fixly?</Trans> (4.7{' '}
        <StarIcon className="appReviews__star" aria-hidden />)
      </Heading3>
      <TextMeta big className="appReviews__subTitle">
        <Trans>Na podstawie 950 opinii z App Store</Trans>
      </TextMeta>
      <div className="appReviews__wrap">
        <Slider {...sliderSettings}>
          {reviews.map((item, index) => (
            <div key={`review-${index}`} className="appReviews__item">
              <RatingStars rating={item.rating} size="normal" className="appReviews__stars" />
              <div className="appReviews__author">
                {item.author && `${item.author}, `}
                <time dateTime={item.date.split('/').reverse().join('-')}>{item.date}</time>
              </div>
              <TextP3 dark className="appReviews__description">
                {item.description}
              </TextP3>
              <TextP3 className="appReviews__store">{item.store}</TextP3>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default AppReviews;
