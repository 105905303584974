import React, { FC, useEffect } from 'react';
import YouTube, { Options as YoutubeOptions } from 'react-youtube';

import analytics from 'utils/analytics/analytics';
import Modal from 'common/Modal/Modal';
import platformDetector from 'common/helpers/platformDetector';

type Props = {
  open: boolean;
  handleClose: () => void;
};

const VIDEO_ID = 'Eaah8RtX-1g';

const PromoVideoModal: FC<Props> = (props) => {
  const options: YoutubeOptions = {
    playerVars: {
      autoplay: props.open ? 1 : 0,
      cc_load_policy: 1,
    },
    width: '854',
    height: '480',
  };

  if (platformDetector.isAnyMobile()) {
    options.width = '100%';
  }
  useEffect(() => {
    if (props.open) {
      analytics.trackEvent('sp_registration_video_click');
    }
  }, [props.open]);

  return (
    <Modal isOpen={props.open} handleClose={props.handleClose}>
      <YouTube className="promoVideoModal__media" videoId={VIDEO_ID} opts={options}></YouTube>
    </Modal>
  );
};

export default PromoVideoModal;
