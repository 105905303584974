import { stripErrorPrefixes } from 'common/helpers/helpers';
import { showFlashMessage } from 'Components/FlashMessage/FlashMessage.helpers';

export default function showGlobalError(message: string): void {
  const formattedMessage = stripErrorPrefixes(message);

  showFlashMessage({
    message: formattedMessage,
    status: 'error',
  });
}
