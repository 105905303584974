import React, { FC } from 'react';

import { useCurrentUser } from 'Components/CurrentUser/CurrentUser';
import PageScroller from 'Components/PageScroller/PageScroller';
import PageView from 'common/PageView/PageView';
import { isWindow } from 'common/helpers/helpers';
import { ReactComponent as HelpIcon } from 'common/icons/question.svg';
import config from 'config/config';
import routes from 'utils/routeTranslator';

import RegisterMeta from '../ProviderRegister/RegisterMeta/RegisterMeta';
import AppReviews from './AppReviews/AppReviews';
import Benefits from './Benefits';
import ProviderBlog from './Blog/ProviderBlog';
import BrandStats from './BrandStats/BrandStats';
import UkrainianDisclaimer from './Disclaimer/Disclaimer';
import Faq from './Faq/Faq';
import GetOrder from './GetOrder';
import RegisterHero from './Hero/RegisterHero';
import MediaAboutUs from './MediaAboutUs';
import ProviderRegisterOrders from './Orders/ProviderRegisterOrders';
import RegisterOrdersByCities from './OrdersByCities/OrdersByCities';
import {
  defaultPageDescription,
  defaultPageTitle,
  ukrainianHelpDeskPage,
  redirectAuthenticatedUser,
} from './ProviderRegister.helpers';
import './ProviderRegister.scss';

const helpCenterLink = (
  <a href={ukrainianHelpDeskPage} target="_blank" rel="noopener noreferrer">
    <HelpIcon className="providerRegister__icon" aria-hidden />
    Довідковий центр
  </a>
);

const ProviderRegister: FC = () => {
  // redirect authenticated user to dashboard
  const { currentUser } = useCurrentUser();
  if (isWindow && currentUser && currentUser.userId) {
    /* @ts-expect-error type mismatch */
    return redirectAuthenticatedUser(currentUser.isProvider);
  }

  return (
    <main className="providerRegister">
      <RegisterMeta
        title={defaultPageTitle}
        description={defaultPageDescription}
        canonicalUrl={config.SITE_URL + routes.get('front::provider.register')}
      />

      <UkrainianDisclaimer />
      <PageView pageLabel="provider_registration_start" />
      <RegisterHero suffixContent={helpCenterLink} />
      <AppReviews />
      <Benefits />
      <MediaAboutUs />
      <GetOrder className="providerRegister__ctaSection" />
      <BrandStats />
      <Faq />
      <ProviderRegisterOrders />
      <RegisterOrdersByCities />
      <ProviderBlog />
      <PageScroller />
    </main>
  );
};

export default ProviderRegister;
