import classnames from 'classnames';
import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import Collapsible from 'uikit/Collapsible/Collapsible';
import { TextDisplay } from 'uikit/Text';

import './OrdersAccordion.scss';

export interface OrderNode {
  id: string;
  name: string;
  url: string;
  icon?: ReactNode;
  children?: OrderNode[];
}

type Props = {
  className?: string;
  orders: OrderNode[];
  expandedIndex?: number;
  numberOfVisible?: number;
};

const defaultNumberOfVisible = 3;

const OrderHeading: FC<OrderNode> = (props) => {
  return (
    <span className="ordersAccordion__heading">
      {props.icon && <span className="ordersAccordion__icon">{props.icon}</span>}
      <TextDisplay as="span">{props.name}</TextDisplay>
    </span>
  );
};

const OrdersAccordion: FC<Props> = (props) => {
  const hostCN = classnames('ordersAccordion', props.className);
  const getToggleWrapCN = (index: number): string =>
    classnames('ordersAccordion__section', {
      'visually-hidden': index >= (props.numberOfVisible || defaultNumberOfVisible),
    });

  return (
    <div className={hostCN}>
      {props.orders.map((section, index) => {
        return (
          <Collapsible
            summary={<OrderHeading {...section} />}
            key={section.id}
            id={section.id}
            open={index === props.expandedIndex || index === 0}
            className={getToggleWrapCN(index)}
            headingClassName="ordersAccordion__toggle"
          >
            <ul className="ordersAccordion__children">
              {section.children?.map((order) => {
                return (
                  <li key={`order-${order.id}`} className="ordersAccordion__item">
                    <Link to={order.url}>{order.name}</Link>
                  </li>
                );
              })}
            </ul>
          </Collapsible>
        );
      })}
    </div>
  );
};

export default OrdersAccordion;
