import { gql } from '@apollo/client';

type Blog = {
  id: string;
  link: string;
  title: string;
  createdAt: string;
  img: string;
};

export type ProviderBlogResponse = {
  blog: Blog[];
};

export const providerBlogQuery = gql`
  query ProviderBlog($type: BlogTypeEnum) {
    blog(type: $type) {
      id
      link
      title
      createdAt
      img
    }
  }
`;
