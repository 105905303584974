import classnames from 'classnames';
import React, { useState, FC, ReactNode } from 'react';

import { Heading2 } from 'uikit/Heading';

import './Faq.scss';

type FaqProps = {
  question: string;
  answer: ReactNode;
};

const questions = [
  {
    question: 'Як почати користуватись Fixly?',
    // eslint-disable-next-line max-len
    answer:
      'Почни зі створення профілю. Вибери, чим ти займаєшся, введи назву свого міста та адресу електронної пошти. На наступному кроці заповни інформацію про себе та додай зображення профілю. Не забудь також додати фотографії своїх виконаних робіт, щоб зацікавити замовників і збільшити твої шанси на отримання замовлення.',
  },
  {
    question: 'Як знайти нових клієнтів на Fixly?',
    // eslint-disable-next-line max-len
    answer:
      'Тобі навіть не потрібно їх шукати! Коли замовник додасть на веб-сайт запит щодо послуг, які ти надаєш - ми повідомимо тебе про це. Якщо замовлення зацікавить, ти можеш зв’язатися з замовником, уточнити всі деталі та домовитися про виконання послуг.',
  },
  {
    question: 'Як отримати відгуки на Fixly?',
    // eslint-disable-next-line max-len
    answer:
      'Від кількості відгуків на твоєму профілі залежить, чи візьме замовник телефон, щоб подзвонити і домовитися про послугу. Чим більше думок, тим більше робочих місць. Навіть якщо ти ще не виконав жодного замовлення з Fixly, ти можеш отримати рекомендації від клієнтів за межами нашого веб-сайту. Все, що тобі потрібно зробити, це перейти в Налаштування у своєму обліковому записі, потім на вкладку Відгуки та надіслати їм запит на відгук. Завдяки цьому ти почнеш отримувати замовлення швидше.',
  },
];

const FaqItem: FC<FaqProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const questionCN = classnames('providerRegisterFaq', {
    'providerRegisterFaq_open': isOpen,
  });

  return (
    <div className={questionCN}>
      <button className="providerRegisterFaq__question" type="button" onClick={() => setIsOpen(!isOpen)}>
        {question}
      </button>
      <div className="providerRegisterFaq__panel">
        <p className="providerRegisterFaq__answer">{answer}</p>
      </div>
    </div>
  );
};

const Faq = () => {
  return (
    <section className="providerRegisterFaqs" id="faq" lang="uk">
      <div className="providerRegisterFaqs__container">
        <Heading2>Перегляньте відповіді на найпопулярніші запитання</Heading2>
        <div className="providerRegisterFaqs__accordion">
          {questions.map((item) => (
            <FaqItem key={item.question} question={item.question} answer={item.answer} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faq;
