import { t } from '@lingui/macro';
import { useLocation } from 'react-router-dom';

import config from '../../config/config';
import routes from 'utils/routeTranslator';

export type SpRegistration = {
  metaTitle?: string;
  metaDescription?: string;
  coverUrl?: string;
  mobileCoverUrl?: string;
};

export const minRequestsCount = 6;

export const getWeekRequestsText = (count?: number): string => {
  const requestsCount = Math.max(minRequestsCount, count || 0);
  return t`${requestsCount} zleceń w ostatnim tygodniu`;
};

export const redirectAuthenticatedUser = (isProvider: boolean | null) => {
  window.location.href = routes.get(isProvider ? 'front::dashboard' : 'front::index');
  return null;
};

export const mainPageBreadcrumb = {
  name: t`Strona główna`,
  url: routes.get('front::provider.register'),
};

export const mainPageBreadcrumbSeo = {
  name: t`Fixly dla wykonawców`,
  url: config.SITE_URL + routes.get('front::provider.register'),
};

export const defaultPageTitle = t`Fixly dla wykonawców - Załóż konto i zdobywaj zlecenia - Logowanie i rejestracja`;
// eslint-disable-next-line max-len
export const defaultPageDescription = t`Zlecenia budowlane i remontowe, projekt domu, usługi prawne, sprzątanie, praca dla elektryka, stolarza, hydraulika i złotej rączki - zlecenia czekają na Fixly.pl!`;

export const useTouchPointButton = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  return params.get('touch_point_button');
};
