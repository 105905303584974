import routes from 'utils/routeTranslator';

export type SpRegistration = {
  metaTitle?: string;
  metaDescription?: string;
  coverUrl?: string;
  mobileCoverUrl?: string;
};

export const minRequestsCount = 6;

export const getWeekRequestsText = (count?: number): string => {
  const requestsCount = Math.max(minRequestsCount, count || 0);
  return `${requestsCount} замовлень за останній тиждень`;
};

export const redirectAuthenticatedUser = (isProvider: boolean | null) => {
  window.location.href = routes.get(isProvider ? 'front::dashboard' : 'front::index');
  return null;
};

export const mainPageBreadcrumb = {
  name: 'Головна',
  url: routes.get('front::provider.register'),
};

export const defaultPageTitle =
  'Fixly для фахівців - Створіть обліковий запис і отримуйте замовлення - Вхід та реєстрація';
// eslint-disable-next-line max-len
export const defaultPageDescription =
  'Замовлення на будівництво та ремонт, проектування будинку, юридичні послуги, прибирання, роботи електрика, столяра, сантехніка та різноробочого – замовлення чекають на Fixly.pl! ';
// eslint-disable-next-line max-len
export const ukrainianHelpDeskPage =
  'https://pomoc.fixly.pl/hc/pl/articles/4570258045970-%D0%AF%D0%BA-%D1%81%D1%82%D0%B2%D0%BE%D1%80%D0%B8%D1%82%D0%B8-%D0%BF%D1%80%D0%BE%D1%84%D1%96%D0%BB%D1%8C-%D1%96-%D0%BD%D0%B0%D0%B4%D0%B0%D0%B2%D0%B0%D1%82%D0%B8-%D0%BF%D0%BE%D1%81%D0%BB%D1%83%D0%B3%D0%B8-%D0%BD%D0%B0-Fixly-';
