import classnames from 'classnames';
import React, { FC } from 'react';

import { Button } from 'uikit/Button';
import { Heading2 } from 'uikit/Heading';
import { TextDisplay } from 'uikit/Text';
import analytics from 'utils/analytics/analytics';

import './GetOrder.scss';
import { ReactComponent as MedalImage } from './images/medal.svg';

type Props = {
  className?: string;
};

const GetOrder: FC<Props> = (props) => {
  const hostCN = classnames('getOrder', props.className);

  return (
    <section className={hostCN} lang="uk">
      <div className="getOrder__layout container">
        <div className="getOrder__text">
          <Heading2 className="getOrder__title">Отримуй замовлення безкоштовно!</Heading2>
          <TextDisplay className="getOrder__description">
            Залучай нових клієнтів у вашому регіоні. Вигравай замовлення{' '}
            <strong>без обмежень у безкоштовних категоріях (це близько 50% усіх замовлень на Fixly!).</strong> У решті
            тестуй програму Fixly і отримай до 5 перших замовлень повністю безкоштовно, використовуючи привітальний
            пакет.
          </TextDisplay>
          <Button
            as="a"
            href="#registration"
            kind="secondary"
            className="getOrder__button"
            onClick={() => analytics.trackEvent('sp_registration_scroll_to_reg', { rel_entity_id: 'try_fixly' })}
          >
            Спробувати Fixly
          </Button>
        </div>
        <div className="getOrder__image">
          <MedalImage className="getOrder__imageSvg" />
        </div>
      </div>
    </section>
  );
};

export default GetOrder;
