import { gql } from '@apollo/client';
import { plural } from '@lingui/macro';

import { OrderNode } from 'Components/OrdersAccordion/OrdersAccordion';
import categoryIcon from 'uikit/Icons/Categories';
import routes from 'utils/routeTranslator';

type AbstractCategory = {
  id: string;
  name: string;
  slug: string;
};

type ParentCategory = AbstractCategory & { requestCountSinceLastWeek: number; categories: AbstractCategory[] };

export type OrdersResponse = {
  topCategoryList: ParentCategory[];
};
export const shortListedCategories: string[] = ['110', '109', '105', '116', '106'];

export const sortCategoriesByIds = (categories: ParentCategory[], ids: string[]): ParentCategory[] => {
  const topOfTheList: ParentCategory[] = [];
  ids.forEach((id) => {
    const item = categories.find((category) => category.id === id);
    if (item) topOfTheList.push(item);
  });
  const rest: ParentCategory[] = categories.filter((category) => !ids.includes(category.id));

  return [...topOfTheList, ...rest];
};

export const buildOrdersTree = (categories: ParentCategory[]): OrderNode[] => {
  const getTitle = (count: number, name: string): string =>
    plural(count, {
      one: `# Zlecenie w kategorii “${name}” w tym tygodniu`,
      few: `# Zlecenia w kategorii “${name}” w tym tygodniu`,
      other: `# Zleceń w kategorii “${name}” w tym tygodniu`,
    });

  return categories
    .filter((l2) => l2.categories.length)
    .map((l2) => {
      return {
        id: l2.id,
        name: getTitle(l2.requestCountSinceLastWeek || 0, l2.name),
        icon: categoryIcon(l2.slug),
        url: routes.get('front::provider.register.l2', { slug: l2.slug }),
        children: l2.categories.map((l3) => {
          return {
            id: l3.id,
            name: l3.name,
            url: routes.get('front::provider.register.l3', { slug: l3.slug }),
          };
        }),
      };
    });
};

export const ordersQuery = gql`
  query Orders($city: String, $ids: [ID]) {
    topCategoryList(city: $city, ids: $ids) {
      id
      name
      slug
      requestCountSinceLastWeek
      categories {
        id
        name
        slug
      }
    }
  }
`;
