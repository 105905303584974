import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FC } from 'react';

import { Button } from 'uikit/Button';
import { Heading2 } from 'uikit/Heading';
import { TextDisplay } from 'uikit/Text';
import analytics from 'utils/analytics/analytics';

import './GetOrder.scss';
import { ReactComponent as MedalImage } from './images/medal.svg';

type Props = {
  className?: string;
};

const GetOrder: FC<Props> = (props) => {
  const hostCN = classnames('getOrder', props.className);

  return (
    <section className={hostCN}>
      <div className="getOrder__layout container">
        <div className="getOrder__text">
          <Heading2 className="getOrder__title">
            <Trans>Zdobywaj zlecenia za darmo!</Trans>
          </Heading2>
          <TextDisplay className="getOrder__description">
            <Trans>
              Pozyskaj nowych klientów z Twojej okolicy. Zdobywaj zlecenia{' '}
              <strong>bez ograniczeń w kategoriach bezpłatnych (to aż ok. 50% wszystkich zleceń na Fixly!)</strong>. W
              pozostałych przetestuj aplikację Fixly i zdobądź do 5 pierwszych zleceń całkowicie za darmo, korzystając z
              Pakietu Powitalnego.
            </Trans>
          </TextDisplay>
          <Button
            as="a"
            href="#registration"
            kind="secondary"
            className="getOrder__button"
            onClick={() => analytics.trackEvent('sp_registration_scroll_to_reg', { rel_entity_id: 'try_fixly' })}
          >
            <Trans>Wypróbuj fixly</Trans>
          </Button>
        </div>
        <div className="getOrder__image">
          <MedalImage className="getOrder__imageSvg" />
        </div>
      </div>
    </section>
  );
};

export default GetOrder;
