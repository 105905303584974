import { t, Trans } from '@lingui/macro';
import React from 'react';

import BlurryIconList, { ListItem } from 'Components/BlurryIconList/BlurryIconList';
import { Heading2 } from 'uikit/Heading';

import './Benefits.scss';

const benefits: ListItem[] = [
  {
    icon: 'flash',
    title: t`1,2 mln zarejestrowanych klientów`,
    lead: t`Dostęp do bazy ponad 1 200 000 klientów szukających usług`,
  },
  {
    icon: 'clock',
    title: t`Zarządzanie czasem`,
    lead: t`Samodzielnie decydujesz komu i ile wyślesz ofert`,
  },
  {
    icon: 'message',
    title: t`Szybkość`,
    lead: t`Błyskawiczny kontakt z wybranymi przez Ciebie zlecającymi`,
  },
  {
    icon: 'profile',
    title: t`Widoczność w sieci`,
    lead: t`Atrakcyjna strona biznesowa, dostępna również dla klientów spoza Fixly`,
  },
  {
    icon: 'star',
    title: t`Wiarygodność`,
    lead: t`Możliwość zebrania opinii od wszystkich Twoich klientów w jednym miejscu`,
  },
  {
    icon: 'bag',
    title: t`Dopasowane zlecenia`,
    lead: t`Otrzymujesz zlecenia zgodne z zakresem Twoich usług`,
  },
  {
    icon: 'shield',
    title: t`Wygoda`,
    lead: t`Prosta i intuicyjna aplikacja mobilna, z którą nie przegapisz żadnego zlecenia`,
  },
  {
    icon: 'note',
    title: t`Szczegółowe zapytania`,
    lead: t`Wiesz wszystko o zakresie zlecenia jeszcze zanim wyślesz swoją ofertę`,
  },
];

const Benefits = () => {
  return (
    <section className="benefits">
      <Heading2>
        <Trans>Co zyskujesz dzięki Fixly?</Trans>
      </Heading2>
      <div className="benefits__wrap">
        <BlurryIconList items={benefits} />
      </div>
    </section>
  );
};

export default Benefits;
