import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { Component, KeyboardEvent, MouseEvent, ChangeEvent } from 'react';
import { animateScroll as scroll } from 'react-scroll';

import { ENTER_KEY, ESC_KEY, TAB_KEY, ARROW_UP_KEY, ARROW_DOWN_KEY } from 'common/helpers/keyCodes';
import platformDetector from 'common/helpers/platformDetector';
import config from 'config/config';
import analytics from 'utils/analytics/analytics';
import { client } from 'utils/apolloConnector/apolloConnector';

import {
  LocationSelect$Props,
  LocationSelect$State,
  CitySuggestion,
  searchCityByCoordsQuery,
} from './LocationSearch.helpers';
import withData from './LocationSearchData';
import './LocationSelect.scss';

class LocationSelect extends Component<LocationSelect$Props, LocationSelect$State> {
  static MOBILE_PREDEFINED_COUNT = 5;
  isMobile = Boolean(platformDetector.isAnyMobile());
  isIOS = Boolean(platformDetector.iOS());
  isSelectionCallThrottled = false;
  locationSelectMounted = false;
  suggestionsRef: HTMLUListElement | null = null;

  state: LocationSelect$State = {
    isLoadingSuggestions: false,
    isDropDownOpen: false,
    inputValue: '',
    suggestions: [],
    selectedSuggestion: undefined,
    isDetectLocationLoading: false,
    isLocationPermissionDenied: false,
  };

  static defaultProps = {
    isLocationAutodetect: false,
    onCityPrefilled: () => {},
    onError: () => {},
  };

  componentDidMount() {
    this.locationSelectMounted = true;
    const { city, isLocationAutodetect } = this.props;

    if (city) {
      this.prefillCity(city);
    } else if (isLocationAutodetect) {
      this.detectLocation();
    }

    this.checkLocationPermissions();
    this.checkFocusStatus();
  }

  componentWillUnmount() {
    this.locationSelectMounted = false;
  }

  inputElement: HTMLInputElement | null = null;

  setSelectedSuggestion = (suggestionId: number): void => {
    if (this.isSelectionCallThrottled) {
      return;
    }

    this.isSelectionCallThrottled = true;

    const { popularCities, bigCities, suggestedCities } = this.props;
    const cities = suggestedCities.length > 0 ? suggestedCities : [...bigCities, ...popularCities];
    const selectedSuggestion = cities.find((s) => Number(s.id) === Number(suggestionId));

    if (!selectedSuggestion) return;

    const inputValue = selectedSuggestion.name || this.state.inputValue;

    analytics.trackEvent('location_search_complete', {
      chosen_result: selectedSuggestion.name,
    });

    this.setState(
      {
        selectedSuggestion,
        inputValue,
        isDropDownOpen: false,
      },
      this.onSubmit
    );

    setTimeout(this.unthrottleSelection, 500);
  };

  unthrottleSelection = (): void => {
    this.isSelectionCallThrottled = false;
  };

  prefillCity = (city: CitySuggestion): void => {
    this.setState(
      ({ isDropDownOpen, selectedSuggestion }: LocationSelect$State) =>
        isDropDownOpen || selectedSuggestion
          ? null
          : {
              selectedSuggestion: city,
              inputValue: city.name,
            },
      () => {
        if (typeof this.props.onCityPrefilled === 'function') {
          this.props.onCityPrefilled(city);
        }
      }
    );
  };

  detectLocation = (): void => {
    this.setState(() => ({ isDetectLocationLoading: true }));

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.processGeolocationResult, this.geolocationFail);
    } else {
      this.geolocationFail();
    }
  };

  checkLocationPermissions = () => {
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then((result) => {
          if (result.state === 'denied') {
            this.setState(() => ({ isLocationPermissionDenied: true }));
          }
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.log('Could not receive geolocation permission', { err });
        });
    }
  };

  checkFocusStatus = () => {
    // If input focused open dropdown
    if (document.activeElement === this.inputElement) {
      this.openDropDown();
      this.registerCloser();
    }
  };

  processGeolocationResult = (position: GeolocationPosition): void => {
    if (position && position.coords) {
      client
        .query({
          query: searchCityByCoordsQuery,
          variables: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
        })
        .then((res: any) => {
          const { searchCity } = res.data;

          if (searchCity && Number(searchCity.id) !== 0) {
            analytics.user.prefillLocationSuccess(searchCity);
            this.prefillCity(searchCity);
            this.setState(() => ({ isDetectLocationLoading: false }));
          }
        })
        .catch(() => {
          analytics.user.prefillLocationFail();
        });
    }
  };

  geolocationFail = () => {
    analytics.user.prefillLocationFail();
    this.setState(() => ({
      isDetectLocationLoading: false,
      isLocationPermissionDenied: true,
    }));
  };

  registerInputRef = (el: HTMLInputElement | null): void => {
    this.inputElement = el;

    if (typeof this.props.inputRef === 'function') {
      this.props.inputRef(el);
    }
  };

  registerSuggestionRef = (el: HTMLUListElement | null): void => {
    this.suggestionsRef = el;
  };

  openDropDown = () => {
    const { isCompact, isSticky, preloadCities, searchCities } = this.props;
    const pureValue = this.state.inputValue.trim();

    analytics.trackEvent('location_search_start');

    preloadCities();
    if (pureValue.length >= config.MIN_CITY_SEARCH_QUERY_LENGTH) {
      searchCities(pureValue);
    }

    this.setState(() => ({ isDropDownOpen: true }));

    if (this.isMobile && !isSticky && this.inputElement && !this.isIOS && !isCompact) {
      scroll.scrollTo(window.scrollY + this.inputElement.getBoundingClientRect().top, { duration: 400 });
    }
  };

  closeDropDown = (e?: UIEvent) => {
    const shouldPreventDropdownClosing =
      e &&
      (e.target === this.inputElement ||
        (this.suggestionsRef && e.target instanceof Node && this.suggestionsRef.contains(e.target)));

    if (e && e.type === 'mouseup' && shouldPreventDropdownClosing) {
      return;
    }

    if (this.state.inputValue.length > 0) {
      this.selectFirstSuggestion(e);
    }

    this.setState(() => ({
      isDropDownOpen: false,
    }));

    window.removeEventListener('mouseup', this.closeDropDown);
  };

  registerCloser = (event?: MouseEvent | KeyboardEvent): void => {
    if (event && event.type === 'mouseup') {
      window.addEventListener(
        'mouseup',
        () => {
          window.addEventListener('mouseup', this.closeDropDown);
        },
        { once: true }
      );
    } else {
      window.addEventListener('mouseup', this.closeDropDown);
    }
  };

  onSubmit = () => {
    const { onSubmit, onError } = this.props;
    const { inputValue, selectedSuggestion, suggestions } = this.state;
    const pureValue = inputValue.trim();

    if (selectedSuggestion) {
      onSubmit(selectedSuggestion);
    } else if (pureValue.length > 0) {
      const matchedSuggestion = suggestions.find((s) => s.name.toLowerCase() === pureValue.toLowerCase());

      if (matchedSuggestion) {
        this.setState(() => ({
          selectedSuggestion: matchedSuggestion,
          inputValue: matchedSuggestion.name,
        }));

        onSubmit(matchedSuggestion);
      } else if (typeof onError === 'function') {
        onError();
      }
    } else if (typeof onError === 'function') {
      onError();
    }
  };

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { suggestedCities, bigCities, predefinedCities } = this.props;
    const { value } = event.currentTarget;
    const pureValue = value.trim();
    const cities = suggestedCities.length > 0 ? suggestedCities : predefinedCities;

    if (pureValue.length >= config.MIN_CITY_SEARCH_QUERY_LENGTH || pureValue.length === 0) {
      this.props.searchCities(pureValue);
    }

    if (typeof this.props.onChange === 'function') {
      this.props.onChange(value);
    }

    this.setState((state: LocationSelect$State) => ({
      inputValue: value,
      selectedSuggestion: undefined,
      isLoadingSuggestions:
        state.isLoadingSuggestions && pureValue.length < config.MIN_CITY_SEARCH_QUERY_LENGTH
          ? false
          : state.isLoadingSuggestions,
      suggestions: pureValue.length < config.MIN_CITY_SEARCH_QUERY_LENGTH ? bigCities : cities,
    }));
  };

  handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    const { keyCode, shiftKey, ctrlKey, metaKey } = event;

    if (keyCode === ESC_KEY) {
      this.closeDropDown();
      return;
    }

    if (keyCode === ENTER_KEY || keyCode === TAB_KEY) {
      this.selectFirstSuggestion(event);
      return;
    }

    if ((keyCode !== ARROW_UP_KEY && keyCode !== ARROW_DOWN_KEY) || shiftKey || ctrlKey || metaKey) {
      return;
    }

    event.preventDefault();
    // NOTE: direct DOM manipulation, 🤞
    const selectedSuggestion: HTMLElement | null = document.querySelector(
      'input[type=radio][name=citySuggestion]:checked'
    );
    let hasToFocusFirstSuggestion = false;

    if (selectedSuggestion) {
      const nextSuggestion: HTMLElement | null =
        selectedSuggestion.parentElement && selectedSuggestion.parentElement.nextElementSibling
          ? selectedSuggestion.parentElement.nextElementSibling.querySelector('input[type=radio][name=citySuggestion]')
          : null;

      if (nextSuggestion === null) {
        hasToFocusFirstSuggestion = true;
      } else {
        nextSuggestion.click();
        nextSuggestion.focus();
      }
    }

    if (selectedSuggestion === null || hasToFocusFirstSuggestion) {
      const firstSuggestion: HTMLElement | null = document.querySelector('input[type=radio][name=citySuggestion]');
      if (firstSuggestion) {
        firstSuggestion.click();
        firstSuggestion.focus();
      }
    }
  };

  selectFirstSuggestion = (event?: KeyboardEvent<HTMLInputElement> | UIEvent) => {
    const { isDropDownOpen, selectedSuggestion, inputValue } = this.state;
    const { popularCities, bigCities, suggestedCities } = this.props;
    const predefinedCities = [...popularCities, ...bigCities];
    const cities = suggestedCities.length > 0 ? suggestedCities : predefinedCities;

    if (!isDropDownOpen || cities.length === 0 || selectedSuggestion) {
      return;
    }

    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const suggestions = inputValue.length < config.MIN_CITY_SEARCH_QUERY_LENGTH ? predefinedCities : cities;
    const firstSuggestion = suggestions[0];

    if (firstSuggestion) {
      this.setSelectedSuggestion(+firstSuggestion.id);
    }

    if (event && event.currentTarget) {
      if ('blur' in event.currentTarget) event.currentTarget.blur();
    }
  };

  handleBlur = (): void => {
    if (typeof this.props.onInputBlur === 'function') {
      this.props.onInputBlur();
    }
  };

  handleSuggestionKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    const {
      keyCode,
      shiftKey,
      currentTarget: { value },
    } = event;
    if (
      ((keyCode >= 65 && keyCode <= 90) || (keyCode >= 97 && keyCode <= 122) || keyCode === 32 || keyCode === 8) &&
      this.inputElement
    ) {
      this.inputElement.focus();
    }

    if (keyCode === TAB_KEY && !shiftKey) {
      // If tab pressed and direction is forward
      this.closeDropDown();
    }

    if (keyCode === ENTER_KEY) {
      this.setSelectedSuggestion(Number(value));
      this.closeDropDown();
    }
  };

  handleSuggestionLabelClick = (event: MouseEvent<HTMLLabelElement>) => {
    this.setSelectedSuggestion(Number(event.currentTarget.dataset.suggestionId));
  };

  renderSuggestionItem = (suggestion: CitySuggestion) => {
    const suggestionId = `suggestion__${suggestion.id}`;
    return (
      <li className="locationSelect__suggestion" key={suggestionId}>
        <input
          type="radio"
          id={suggestionId}
          onKeyDown={this.handleSuggestionKeyDown}
          value={suggestion.id}
          name="citySuggestion"
          className="locationSelect__suggestionInput visually-hidden"
          tabIndex={-1}
        />
        <label
          htmlFor={suggestionId}
          className="locationSelect__suggestionLabel"
          onClick={this.handleSuggestionLabelClick}
          data-suggestion-id={suggestion.id}
        >
          {suggestion.name}
          <span className="locationSelect__suggestionRegion">, {suggestion.region.name}</span>
        </label>
      </li>
    );
  };

  renderPopularItem = (suggestion: CitySuggestion, index: number, arr: CitySuggestion[]) => {
    const suggestionId = `predefinedCity__${suggestion.id}`;
    const suggestionCN = classnames({
      'locationSelect__suggestionLabel locationSelect__suggestionLabel_predefined': true,
      'locationSelect__suggestionLabel_hiddenMobile': index + 1 > LocationSelect.MOBILE_PREDEFINED_COUNT,
      'locationSelect__suggestionLabel_lastDesktop': index + 1 === arr.length,
      'locationSelect__suggestionLabel_lastMobile': index + 1 === LocationSelect.MOBILE_PREDEFINED_COUNT,
    });

    return (
      <li className="locationSelect__suggestion" key={suggestionId}>
        <input
          type="radio"
          id={suggestionId}
          onKeyDown={this.handleSuggestionKeyDown}
          value={suggestion.id}
          name="citySuggestion"
          className="locationSelect__suggestionInput visually-hidden"
          tabIndex={-1}
        />
        <label
          htmlFor={suggestionId}
          className={suggestionCN}
          onClick={this.handleSuggestionLabelClick}
          data-suggestion-id={suggestion.id}
        >
          {suggestion.name}
        </label>
      </li>
    );
  };

  renderSuggestionsList = () => {
    const { inputValue, isDropDownOpen, isLoadingSuggestions } = this.state;
    const { popularCities, bigCities, suggestedCities } = this.props;
    const pureValue = inputValue.trim();
    const showBigCities = pureValue.length === 0 && suggestedCities.length === 0;
    const cities = showBigCities ? bigCities : suggestedCities;

    if (pureValue.length >= config.MIN_CITY_SEARCH_QUERY_LENGTH && cities.length === 0) {
      return null;
    }

    const dropdownCN = classnames({
      'locationSelect__suggestionsWrapper': true,
      'locationSelect__suggestionsWrapper_open': isDropDownOpen,
      'locationSelect__suggestionsWrapper_loading': isLoadingSuggestions,
    });

    return (
      <ul className={dropdownCN} ref={this.registerSuggestionRef}>
        {pureValue.length < config.MIN_CITY_SEARCH_QUERY_LENGTH &&
          popularCities &&
          popularCities.map(this.renderPopularItem)}
        {cities.map(this.renderSuggestionItem)}
      </ul>
    );
  };

  render() {
    const { inputValue, isDropDownOpen, selectedSuggestion } = this.state;

    const { isError, errorMessage, isCompact, placeholder, testid, neighbourhood, onFocus } = this.props;

    const inputWrapperCN = classnames({
      'locationSelect__inputWrapper': true,
      'locationSelect__inputWrapper_error': isError,
      'locationSelect__inputWrapper_compact': isCompact,
    });

    const inputCN = classnames({
      'locationSelect__input': true,
      'locationSelect__input_open': isDropDownOpen,
      'locationSelect__input_error input_error': isError,
      'locationSelect__input_compact': isCompact,
    });

    const listWrapperCN = classnames({
      'locationSelect__listWrapper': true,
      'locationSelect__listWrapper_open': isDropDownOpen,
      'locationSelect__listWrapper_compact': isCompact,
    });

    const formatedOutputCN = classnames({
      'locationSelect__formatedOutput': true,
      'locationSelect__formatedOutput_hidden': isDropDownOpen,
      'locationSelect__formatedOutput_compact': isCompact,
    });

    const inputPlaceholder = placeholder || (isCompact ? t`Wpisz nazwę miejscowości` : t`Wybierz lokalizację`);

    return (
      <div className="locationSelect">
        <div className="locationSelect__wrapper">
          <div className={inputWrapperCN}>
            <div className={formatedOutputCN}>
              <span className="locationSelect__formatedOutputWrap">
                {selectedSuggestion ? (
                  <span className="locationSelect__formatedOutputCity">
                    {selectedSuggestion.name}
                    {neighbourhood ? `, ${neighbourhood.name}` : ''}
                    <span className="locationSelect__formatedOutputRegion">, {selectedSuggestion.region.name}</span>
                  </span>
                ) : (
                  <span className="locationSelect__formatedOutputPlaceholder">{inputValue || inputPlaceholder}</span>
                )}
              </span>
            </div>
            <input
              id={this.props.id || 'locationSearchInput'}
              type="text"
              className={inputCN}
              value={inputValue}
              onFocus={(e) => {
                this.openDropDown();
                if (typeof onFocus === 'function') onFocus(e);
              }}
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
              onKeyUp={this.registerCloser}
              onMouseUp={this.registerCloser}
              placeholder={inputPlaceholder}
              ref={this.registerInputRef}
              autoComplete="off"
              data-testid={testid}
            />

            {isError && <div className="locationSelect__errorMessage">{errorMessage}</div>}

            <div className={listWrapperCN}>{this.renderSuggestionsList()}</div>
          </div>

          {!isCompact && (
            <button
              type="button"
              id="locationSelect__submitBtn"
              className="locationSelect__submitBtn"
              onClick={this.onSubmit}
            >
              <Trans>Znajdź wykonawcę</Trans>
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default withData(LocationSelect);
