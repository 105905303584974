import IntersectionObserver from '@researchgate/react-intersection-observer';
import classnames from 'classnames';
import React, { FC, ReactNode, ReactEventHandler, useState } from 'react';

import ProviderRegisterForm from 'Components/ProviderRegisterForm/ProviderRegisterForm';
import { isGraphqlError, stripGraphqlPrefix } from 'common/helpers/helpers';
import platformDetector from 'common/helpers/platformDetector';
import { ReactComponent as SmartphoneIcon } from 'common/icons/smartphone.svg';
import { ReactComponent as PlayIcon } from 'common/icons/triangle.svg';
import config from 'config/config';
import { Button } from 'uikit/Button';
import { Heading1, Heading3 } from 'uikit/Heading';
import { TextDisplay } from 'uikit/Text';
import analytics from 'utils/analytics/analytics';
import { getResponseError } from 'utils/api';
import routes from 'utils/routeTranslator';
import showGlobalError from 'utils/showGlobalError/showGlobalError';

import InstallAppModal from '../InstallAppModal/InstallAppModal';
import PromoVideoModal from '../PromoVideoModal/PromoVideoModal';
import './RegisterHero.scss';

type Props = {
  className?: string;
  l2CategoryId?: string;
  l3CategoryId?: string;
  title?: string;
  prefixContent?: ReactNode;
  suffixContent?: ReactNode;
};

const RegisterHero: FC<Props> = (props) => {
  const [videoModalOpen, setVideoModalOpen] = useState<boolean>(false);
  const [appModalOpen, setAppModalOpen] = useState<boolean>(false);
  const [isStickerVisible, setIsStickerVisible] = useState<boolean>(false);

  const onAppButtonClick: ReactEventHandler<HTMLElement> = (event) => {
    const isDesktop = !platformDetector.isAnyMobile();
    const mobileAppUrl = platformDetector.iOS() ? config.IOS_APP_URL : config.ANDROID_APP_URL;
    if (isDesktop) {
      setAppModalOpen(true);
    } else {
      window.open(mobileAppUrl, '_blank', 'noopener, noreferrer');
    }
    const { entityId } = event.currentTarget.dataset;
    analytics.trackEvent('sp_registration_app_download_click', { rel_entity_id: entityId });
  };

  const isTwoStepRegistrationUIEnabled = !props.l2CategoryId && !props.l3CategoryId;

  const formTitle = isTwoStepRegistrationUIEnabled || props.l2CategoryId ? 'Zarejestruj się na Fixly' : undefined;

  const hostCN = classnames('registerHero', props.className);
  const stickerCN = classnames('registerHero__sticker', {
    registerHero__sticker_visible: isStickerVisible,
  });
  return (
    <section className={hostCN} lang="uk">
      <IntersectionObserver onChange={(entry) => setIsStickerVisible(!entry.isIntersecting)}>
        <div className="registerHero__layout">
          <div className="registerHero__header">
            {props.prefixContent && <div className="registerHero__prefix">{props.prefixContent}</div>}
            <Heading1>Реєструйся на Fixly і знаходь роботу</Heading1>
            {props.suffixContent && <TextDisplay className="registerHero__suffix">{props.suffixContent}</TextDisplay>}
            <Button
              className="registerHero__appButton"
              kind="secondary"
              icon={<SmartphoneIcon aria-hidden />}
              onClick={onAppButtonClick}
              data-entity-id="top_pane"
            >
              Завантажити застосунок
            </Button>

            <button className="registerHero__media" type="button" onClick={() => setVideoModalOpen(true)}>
              <span className="registerHero__play">
                <PlayIcon aria-hidden />
                <span className="visually-hidden">Завантажити з Google Play</span>
              </span>
            </button>
          </div>
          <div className="registerHero__form" id="registration" lang="pl">
            <ProviderRegisterForm
              twoStepUIEnabled={isTwoStepRegistrationUIEnabled}
              id="registration-form"
              title={formTitle}
              l2CategoryIds={props.l2CategoryId ? [props.l2CategoryId] : undefined}
              l3CategoryIds={props.l3CategoryId ? [props.l3CategoryId] : undefined}
              onRegisterComplete={(provider) => {
                analytics.trackEvent(
                  'sp_registration_success',
                  {
                    user_id: provider.userId,
                  },
                  () => (window.location.href = routes.get('provider::onboarding'))
                );
              }}
              onRegisterFailed={(error) => {
                const message = isGraphqlError(error) ? stripGraphqlPrefix(error.message) : getResponseError(error);
                showGlobalError(message);
                analytics.trackEvent('sp_registration_error', { rel_entity_type: message });
              }}
            />
          </div>
        </div>
      </IntersectionObserver>

      <div className={stickerCN}>
        <Heading3 as="div">Реєструйся на Fixly і знаходь роботу</Heading3>
        <div className="registerHero__actionLayout">
          <Button
            as="a"
            href="#registration"
            kind="cta"
            onClick={() => analytics.trackEvent('sp_registration_scroll_to_reg', { rel_entity_id: 'sticky_pane' })}
          >
            Створити акаунт
          </Button>
          <Button
            kind="secondary"
            onClick={onAppButtonClick}
            data-entity-id="sticky_pane"
            icon={<SmartphoneIcon aria-hidden />}
          >
            Завантажити застосунок
          </Button>
        </div>
      </div>

      <InstallAppModal open={appModalOpen} handleClose={() => setAppModalOpen(false)} />
      <PromoVideoModal open={videoModalOpen} handleClose={() => setVideoModalOpen(false)} />
    </section>
  );
};

export default RegisterHero;
