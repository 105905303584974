import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { useCurrentUser } from 'Components/CurrentUser/CurrentUser';
import PageScroller from 'Components/PageScroller/PageScroller';
import PageView from 'common/PageView/PageView';
import { isWindow } from 'common/helpers/helpers';
import config from 'config/config';
import routes from 'utils/routeTranslator';

import AppReviews from './AppReviews';
import Benefits from './Benefits';
import ProviderBlog from './Blog/ProviderBlog';
import BrandStats from './BrandStats';
import Faq from './Faq';
import GetOrder from './GetOrder';
import RegisterHero from './Hero/RegisterHero';
import MediaAboutUs from './MediaAboutUs';
import ProviderRegisterOrders from './Orders/ProviderRegisterOrders';
import RegisterOrdersByCities from './OrdersByCities/OrdersByCities';
import { defaultPageDescription, defaultPageTitle, redirectAuthenticatedUser } from './ProviderRegister.helpers';
import './ProviderRegister.scss';
import RegisterMeta from './RegisterMeta/RegisterMeta';

const ProviderRegister: FC = () => {
  // redirect authenticated user to dashboard
  const { currentUser } = useCurrentUser();
  const { search } = useLocation();

  if (isWindow && currentUser && currentUser.isProvider) {
    return redirectAuthenticatedUser(currentUser.isProvider);
  }

  const params = new URLSearchParams(search);
  const isFromOlx = params.get('navigated_from') === 'olx';
  const pageViewParams: Record<string, string> = {};

  if (isFromOlx) {
    pageViewParams.touch_point_page = 'sso';
    pageViewParams.rel_entity_type = 'olx';
  }

  return (
    <main className="providerRegister">
      <RegisterMeta
        title={defaultPageTitle}
        description={defaultPageDescription}
        canonicalUrl={config.SITE_URL + routes.get('front::provider.register')}
      />

      <PageView pageLabel="provider_registration_start" {...pageViewParams} />
      <RegisterHero />
      <AppReviews />
      <Benefits />
      <MediaAboutUs />
      <GetOrder className="providerRegister__ctaSection" />
      <BrandStats />
      <Faq />
      <ProviderRegisterOrders />
      <RegisterOrdersByCities />
      <ProviderBlog />
      <PageScroller />
    </main>
  );
};

export default ProviderRegister;
