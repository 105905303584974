import React, { FC } from 'react';

import Modal from 'common/Modal/Modal';
import AppButtons from 'uikit/AppButtons/AppButtons';
import { TextDisplay } from 'uikit/Text';

import './InstallAppModal.scss';

type Props = {
  open: boolean;
  handleClose: () => void;
};

const InstallAppModal: FC<Props> = (props) => {
  return (
    <Modal
      isOpen={props.open}
      handleClose={props.handleClose}
      className="installAppModal"
      bodyClassName="installAppModal__body"
    >
      <div className="installAppModal__icon" />
      <TextDisplay className="installAppModal__title" bold>
        Завантажте мобільний застосунок Fixly <br /> - не пропустіть жодного замовлення!
      </TextDisplay>
      <AppButtons />
    </Modal>
  );
};

export default InstallAppModal;
