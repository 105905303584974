import { Trans } from '@lingui/macro';
import c from 'classnames';
import React, { FC } from 'react';

import config from 'config/config';
import { IosButton, AndroidButton } from 'uikit/Button';

import './AppButtons.scss';

interface Props {
  className?: string;
  buttonClassName?: string;
  onIosClick?: () => void;
  onAndroidClick?: () => void;
}

const AppButtons: FC<Props> = ({ className, buttonClassName, onIosClick, onAndroidClick }) => {
  return (
    <div className={c('appButtons', className)}>
      <IosButton
        as="a"
        href={config.IOS_APP_URL}
        target="_blank"
        rel="noopener noreferrer"
        className={c('appButtons__button', buttonClassName)}
        onClick={onIosClick}
      >
        <Trans>Pobierz z App Store</Trans>
      </IosButton>
      <AndroidButton
        as="a"
        href={config.ANDROID_APP_URL}
        target="_blank"
        rel="noopener noreferrer"
        className={c('appButtons__button', buttonClassName)}
        onClick={onAndroidClick}
      >
        <Trans>Pobierz z Google Play</Trans>
      </AndroidButton>
    </div>
  );
};

export default AppButtons;
