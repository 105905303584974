import { t } from '@lingui/macro';
import React, { FC, ReactNode } from 'react';

import TermsAndConditions from 'Components/TermsAndConditions/TermsAndConditions';
import { Checkbox } from 'uikit/Checkbox';
import FormRow from 'uikit/FormRow/FormRow';
import analytics from 'utils/analytics/analytics';

import './ProviderRegisterFooter.scss';

type Props = {
  subscribeChecked: boolean;
  onSubscribeChange: (checked: boolean) => void;
  challengeElement?: ReactNode;
};

const ProviderRegisterFooter: FC<Props> = (props) => {
  const trackEvent = (checked: boolean): void => {
    analytics.trackEvent(
      checked ? 'sp_registration_marketing_consent_select' : 'sp_registration_marketing_consent_unselect'
    );
  };

  return (
    <div className="providerRegisterFooter">
      <TermsAndConditions />
      <FormRow>
        <Checkbox
          name="subscribe"
          id="subscribe"
          value="subscribe"
          label={t({
            id: 'register_email_only.subscribe',
            message:
              // eslint-disable-next-line max-len
              'Wyrażam zgodę na używanie przez Grupę OLX sp. z o.o. środków komunikacji elektronicznej oraz telekomunikacyjnych urządzeń końcowych w celu przesyłania mi informacji handlowych oraz prowadzenia marketingu (np. newsletter, wiadomości SMS) przez Grupę OLX sp. z o.o., podmioty powiązane i partnerów biznesowych.',
          })}
          labelClassName="providerRegisterFooter__caption"
          checked={props.subscribeChecked}
          onChange={(e) => {
            const { checked } = e.currentTarget;
            trackEvent(checked);
            props.onSubscribeChange(checked);
          }}
          data-testid="providerSignup__subscribe"
        />
      </FormRow>
      {props.challengeElement}
    </div>
  );
};

export default ProviderRegisterFooter;
