import classnames from 'classnames';
import React, { FC } from 'react';

import { TextDisplay, TextP1 } from 'uikit/Text';

import './BlurryIconList.scss';

type BlurryIconKey =
  | 'shield'
  | 'wallet'
  | 'message'
  | 'math'
  | 'card'
  | 'profile'
  | 'bag'
  | 'clock'
  | 'flash'
  | 'note'
  | 'star';

export type ListItem = {
  title: string;
  lead?: string;
  icon: BlurryIconKey;
};

type Props = {
  className?: string;
  items: ListItem[];
};

const BlurryIconList: FC<Props> = (props) => {
  const hostCN = classnames('blurryIconList', props.className);

  const getIconCN = (key: string): string => classnames('blurryIconList__icon', `blurryIconList__icon_${key}`);

  return (
    <ul className={hostCN}>
      {props.items.map((item, index) => (
        <li className="blurryIconList__row" key={item.icon + index}>
          <span className={getIconCN(item.icon)} />
          <div>
            <TextDisplay bold>{item.title}</TextDisplay>
            {item.lead && <TextP1 className="blurryIconList__lead">{item.lead}</TextP1>}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default BlurryIconList;
