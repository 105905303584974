import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';

import config from 'config/config';

type Props = {
  title: string;
  ogTitle?: string;
  description: string;
  canonicalUrl?: string;
  ogPreviewUrl?: string;
  thumbnail?: string;
};

const RegisterMeta: FC<Props> = (props) => {
  return (
    <Helmet>
      <title>{props.title}</title>
      <meta name="msapplication-tooltip" content={props.description} />
      <meta name="description" content={props.description} />
      <link rel="canonical" href={props.canonicalUrl} />
      <meta property="og:title" content={props.ogTitle || props.title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={props.canonicalUrl} />
      <meta property="og:description" content={props.description} />
      {props.ogPreviewUrl && <meta property="og:image" content={props.ogPreviewUrl} />}
      {props.thumbnail && <meta name="thumbnail" content={props.thumbnail} />}

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content={config.SITE_URL} />
      <meta property="twitter:url" content={props.canonicalUrl} />
      <meta name="twitter:title" content={props.ogTitle || props.title} />
      <meta name="twitter:description" content={props.description} />
      {props.ogPreviewUrl && <meta name="twitter:image" content={props.ogPreviewUrl} />}
    </Helmet>
  );
};

export default RegisterMeta;
