export const TAB_KEY = 9;
export const ENTER_KEY = 13;
export const ESC_KEY = 27;
export const SPACE_KEY = 32;
export const ARROW_LEFT_KEY = 37;
export const ARROW_UP_KEY = 38;
export const ARROW_RIGHT_KEY = 39;
export const ARROW_DOWN_KEY = 40;
export const SEMI_COLON_KEY = 186;
export const COMMA_KEY = 188;
