import { useQuery } from '@apollo/client';
import classnames from 'classnames';
import React, { FC } from 'react';

import ArticleList from 'Components/ArticleList/ArticleList';
import { Heading2 } from 'uikit/Heading';

import { providerBlogQuery, ProviderBlogResponse } from './ProviderBlog.helpers';
import './ProviderBlog.scss';

type Props = {
  className?: string;
};

const ProviderBlog: FC<Props> = (props) => {
  const { data } = useQuery<ProviderBlogResponse>(providerBlogQuery, { variables: { type: 'provider' } });

  if (!data) return null;

  const hostCN = classnames('providerBlog', props.className);
  return (
    <section className={hostCN} id="blog">
      <div className="providerBlog__container">
        <Heading2 className="providerBlog__title">Блог і поради</Heading2>
        <ArticleList items={data.blog} className="providerBlog__cards" />
      </div>
    </section>
  );
};

export default ProviderBlog;
