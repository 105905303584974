import React from 'react';

import BlurryIconList, { ListItem } from 'Components/BlurryIconList/BlurryIconList';
import { Heading2 } from 'uikit/Heading';

import './Benefits.scss';

const benefits: ListItem[] = [
  {
    icon: 'flash',
    title: '1,2 млн зареєстрованих клієнтів',
    lead: 'Доступ до бази понад 1 200 000 клієнтів, які шукають послуги',
  },
  {
    icon: 'clock',
    title: 'Керування часом',
    lead: 'Ти вирішуєш, кому і скільки пропозицій надсилати',
  },
  {
    icon: 'message',
    title: 'Швидкість',
    lead: "Миттєвий зв'язок з обраними вами замовниками",
  },
  {
    icon: 'profile',
    title: 'Видимість в мережі',
    lead: 'Приваблива бізнес-сторінка, доступна для клієнтів за межами Fixly',
  },
  {
    icon: 'star',
    title: 'Достовірність',
    lead: 'Можливість збирати відгуки від усіх твоїх клієнтів в одному місці',
  },
  {
    icon: 'bag',
    title: 'Релевантні замовлення',
    lead: 'Ти отримуєш замовлення відповідно до твоєї спеціалізації',
  },
  {
    icon: 'shield',
    title: 'Зручність',
    lead: 'Простий та інтуїтивно зрозумілий мобільний застосунок, з яким ти не пропустиш жодного замовлення',
  },
  {
    icon: 'note',
    title: 'Деталізовані замовлення',
    lead: 'Ти знатимеш все про обсяг замовлення, перш ніж відправити свою пропозицію',
  },
];

const Benefits = () => {
  return (
    <section className="benefits" lang="uk">
      <Heading2>Що ти отримаєш від Fixly?</Heading2>
      <div className="benefits__wrap">
        <BlurryIconList items={benefits} />
      </div>
    </section>
  );
};

export default Benefits;
